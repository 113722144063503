import React, { useContext } from "react";
import { PosContext } from "../../../context/PosContext";
import { ISubscription } from "../../../interfaces/ITerminal";
import { useTranslation } from "react-i18next";
import { getLocalizedDescription } from "../utilities/getLocalizedDescription";
import { t } from "i18next";
import { formatPrice } from "../utilities/formatPrice";

export interface BuyProps {
  selectedBuyOption: ISubscription | undefined;
  onSelectBuyOption: (option: ISubscription) => void;
}

const Buy: React.FC<BuyProps> = ({ selectedBuyOption, onSelectBuyOption }) => {
  const { orders, activeOrderId } = useContext(PosContext);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const order = orders.find((o) => o.orderId === activeOrderId);

  const buyOption = order?.selectedTerminal?.Subscriptions.find(
    (sub) => sub.Type.includes("koop") || sub.Type.includes("buy")
  );

  const handleSelectBuyOption = () => {
    if (buyOption) {
      onSelectBuyOption(buyOption);
    }
  };

  const buyPrice =
    buyOption?.Fees.find((fee) => fee.Type === "BuyFee")?.Price.toFixed(2) ||
    "N/A";
  const recurringPrice =
    buyOption?.Fees.find((fee) => fee.Type === "RecurringFee")?.Price.toFixed(
      2
    ) || "N/A";
  const oneTimePrice =
    buyOption?.Fees.find((fee) => fee.Type === "OneTimeFee")?.Price.toFixed(
      2
    ) || "N/A";

  return (
    <div className="flex flex-col justify-between">
      <div className="w-full max-w-sm">
        {buyOption && (
          <div
            className={`p-4 border rounded-lg cursor-pointer hover:shadow-lg transition duration-300 ease-in-out text-center ${
              selectedBuyOption?.SubscriptionInvoiceCode ===
              buyOption.SubscriptionInvoiceCode
                ? "border-primary-500 bg-primary-100"
                : "border-gray-300 hover:bg-gray-50"
            }`}
            onClick={handleSelectBuyOption}
          >
            <p className="text-lg font-semibold">
              {getLocalizedDescription(
                buyOption.SubscriptionProductNames,
                currentLanguage
              )}{" "}
              *
            </p>
            <p className="text-sm">
              €{formatPrice(recurringPrice)} / {t("pos.purchaseTotal.month")}
            </p>
          </div>
        )}
      </div>
      {selectedBuyOption && (
        <div className="mt-4 p-4">
          <p className="font-bold text-xl mb-2 text-gray-800">
            {order && order.selectedTerminal && (
              <span>
                {getLocalizedDescription(
                  order.selectedTerminal.ProductDescriptions,
                  currentLanguage
                )}
              </span>
            )}
          </p>
          <div className="space-y-2">
            <p className="text-gray-700">
              {t("pos.purchaseTotal.totalPrice")}
              <span className="font-semibold"> €{formatPrice(buyPrice)}</span>
            </p>
            <p className="text-gray-700">
              {t("pos.purchaseTotal.fee")}
              <span className="font-semibold">
                {" "}
                €{formatPrice(recurringPrice)} / {t("pos.purchaseTotal.month")}
              </span>
            </p>
            <p className="text-gray-700">
              {" "}
              {t("pos.purchaseTotal.oneTimeFee")}{" "}
              <span className="font-semibold">
                €{formatPrice(oneTimePrice)}
              </span>
            </p>
            <span className="text-xs ml-1 font-medium">
              * {t("pos.purchaseTotal.contractNote")}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Buy;
