import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PosContext } from "../../context/PosContext";

export interface ISuccess {
  firstName: string;
  companyName: string;
}

const Success: React.FC<ISuccess> = ({ firstName, companyName }) => {
  const { t, i18n } = useTranslation();
  const { orderType } = useContext(PosContext);

  const getLinkForLanguage = (): string => {
    const language = i18n.language;
    switch (language) {
      case "en":
        return "https://www.buckaroo.eu/large-corporations/resources/integration";
      case "de":
        return "https://www.buckaroo.eu/large-corporations/resources/integration";
      case "fr":
        return "https://www.buckaroo.eu/large-corporations/resources/integration";
      case "nl":
        return "https://www.buckaroo.nl/plugins";
      default:
        return "https://www.buckaroo.nl/";
    }
  };

  const svgBackground = encodeURIComponent(`
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 846 868" fill="none">
    <path
      d="M561.862 365.638H293.028l139.479 312.545 129.355-312.545zM0 0h126.842l115.364 254.285h367.888L719.158 0H846L474.402 868h-87.576L0 0z"
      fill="#F2F6D7"
    />
  </svg>
`);

  return (
    <div
      className="flex items-center justify-center pt-16 text-black"
      style={{
        minHeight: `calc(100vh - 79px)`,
        backgroundImage: `url("data:image/svg+xml,${svgBackground}")`,
        backgroundPosition: "top right",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }}
    >
      <div className="p-2 max-w-2xl mx-6 md:mx-auto">
        <h1 className="text-4xl font-bold mb-6 text-center">
          {t("success.title")} {firstName}!
        </h1>
        <div className="text-lg mb-16 text-center max-w-lg">
          <p> {t("success.p1")}</p>
          <p>
            {t("success.p2")} {companyName}.
          </p>
        </div>

        <h2 className="text-3xl font-bold mb-8 text-center">
          {t("success.p3")}
        </h2>
        <div className="max-w-md mx-auto">
          <ol className="relative border-l-2 border-primary-500 border-dashed">
            <li className="mb-10 ml-4 flex items-center">
              <div className="absolute w-10 h-10 bg-primary-500 rounded-full mt-1.5 -left-5 flex items-center justify-center">
                <span className="text-black text-lg font-semibold">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="3"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              </div>
              <h4 className="text-lg font-semibold text-primary-600 ml-5">
                {t("success.p4")}
              </h4>
            </li>

            <li className="mb-10 ml-4">
              <div className="absolute w-10 h-10 bg-black rounded-full mt-1.5 -left-5 flex items-center justify-center">
                <span className="text-primary-500 text-lg font-semibold">
                  2
                </span>
              </div>{" "}
              <p className="mb-4 ml-4">{t("success.p5")}</p>
            </li>

            {orderType?.type !== "PosOnly" && (
              <li className="mb-10 ml-4">
                <div className="absolute w-10 h-10 bg-black rounded-full mt-1.5 -left-5 flex items-center justify-center">
                  <span className="text-primary-500 text-lg font-semibold">
                    3
                  </span>
                </div>
                <p className="mb-4 ml-4">{t("success.p6")}</p>
                <a
                  href={getLinkForLanguage()}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <button className="ml-4 bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-4 rounded transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-opacity-50">
                    {t("success.button1")}
                  </button>
                </a>
              </li>
            )}

            <li className="ml-4">
              <div className="absolute w-10 h-10 bg-black rounded-full mt-1.5 -left-5 flex items-center justify-center">
                <span className="text-primary-500 text-lg font-semibold">
                  {orderType?.type === "PosOnly" ? 3 : 4}
                </span>
              </div>
              <p className="mb-4 ml-5">
                {orderType?.type === "PosOnly" ? "" : t("success.p7")}{" "}
                {t("success.p8")}
              </p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Success;
