import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import clsx from "clsx";

interface InputProps {
  id?: string;
  name: string;
  label: string;
  type: string;
  placeholder: string;
  onFocus?: () => void;
  onChange?: (e: any) => void;
}

const InputField = ({
  id,
  name,
  label,
  type,
  placeholder,
  onFocus,
  onChange,
}: InputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="flex flex-col w-full font-medium mt-2">
      <span className="inline-flex items-center justify-between">
        <label htmlFor={label} className="text-sm text-primary-marine-blue ">
          {label}
        </label>
      </span>
      <input
        type={type}
        data-testid={id}
        placeholder={placeholder}
        {...register(name)}
        id={id}
        className={clsx(
          `${`bg-white h-full w-full mx-0  ${
            errors[name] ? "border-b border-red-500" : "border-b border-black "
          } px-1 py-2 text-sm focus:outline-none focus:border-1 focus:border-primary-500 `}`
        )}
        onFocus={onFocus}
        onChange={onChange}
      />
      <div className="empty:my-2">
        <ErrorMessage
          name={name}
          render={({ message }) => (
            <div className="text-xs text-red-500 text-right">{message}</div>
          )}
        />
      </div>
    </div>
  );
};

export default InputField;
