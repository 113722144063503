import React, { useContext, useEffect, useState } from "react";
import Lease from "../../components/pos/subscription/Lease";
import Buy from "../../components/pos/subscription/Buy";
import { t } from "i18next";
import { PosContext } from "../../context/PosContext";
import { ISubscription } from "../../interfaces/ITerminal";
import useCustomNavigate from "../../components/pos/hooks/useCustomNavigate";
import Modal from "../../components/pos/ui/Modal";
import { useLocation } from "react-router-dom";

const Subscription: React.FC = () => {
  const { updateOrder, updateSteps, orders, activeOrderId } =
    useContext(PosContext);
  const navigate = useCustomNavigate();
  const order = orders.find((o) => o.orderId === activeOrderId);
  const subscriptions = order?.selectedTerminal?.Subscriptions || [];

  const getDefaultSubscription = () => {
    return order?.selectedSubscription &&
      subscriptions.some(
        (sub) =>
          sub.SubscriptionInvoiceCode ===
          order?.selectedSubscription?.SubscriptionInvoiceCode
      )
      ? order.selectedSubscription
      : subscriptions.find((sub) =>
          ["buy", "koop"].some((type) => sub.Type.toLowerCase().includes(type))
        ) || subscriptions[0];
  };

  const [selectedSubscription, setSelectedSubscription] = useState<
    ISubscription | undefined
  >(getDefaultSubscription());

  function useQueryParameters() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(Array.from(searchParams.entries()));
    return params;
  }

  useEffect(() => {
    const defaultSubscription = getDefaultSubscription();
    setSelectedSubscription(defaultSubscription);
    updateOrder({ ...order, selectedSubscription: defaultSubscription });
  }, [order?.selectedSubscription]);

  const handleOptionChange = (optionType: string) => {
    const newSubscription = subscriptions.find((sub) =>
      optionType === "buy"
        ? sub.Type.toLowerCase().includes("buy") ||
          sub.Type.toLowerCase().includes("koop")
        : sub.Type.toLowerCase().includes(optionType)
    );
    if (newSubscription) {
      setSelectedSubscription(newSubscription);
      updateOrder({ ...order, selectedSubscription: newSubscription });
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleContinue = () => {
    if (selectedSubscription) {
      updateOrder({ ...order, selectedSubscription });
      updateSteps({ subscription: true });
      navigate("/pos/accessories");
    }
  };

  const isOptionSelected = (type: string) => {
    return type === "buy"
      ? selectedSubscription?.Type.toLowerCase().includes("buy") ||
          selectedSubscription?.Type.toLowerCase().includes("koop")
      : selectedSubscription?.Type.toLowerCase().includes(type);
  };

  const handleBack = () => {
    navigate("/pos/terminal");
  };
  const queryParams = useQueryParameters();
  const isPartnerYellow =
    queryParams.partner ===
    "eb653c06e00ff430289f6ae7b3f899b60f2b0e02f2f082f646ce9f2c99b212ba";

  const debitValue = isPartnerYellow ? 0.065 : 0.08;

  return (
    <div className="container mx-auto px-4 py-8 pt-4 bg-white w-full md:max-w-3xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl">
      <div
        className="flex items-center text-primary-500 cursor-pointer mb-6"
        onClick={handleBack}
      >
        <svg
          className="w-6 h-6 mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 19l-7-7 7-7"
          ></path>
        </svg>
        {t("pos.buttons.back.name")}
      </div>
      <h1 className="text-3xl font-bold mb-8 text-left">
        {t("pos.subscription.heading")}
      </h1>{" "}
      <div className="rounded-lg py-2 px-3">
        <div className="flex border-2 border-primary-500 mb-6 w-full sm:w-1/3 md:w-1/5 rounded-lg transition-all">
          {["buy", "lease"].map((optionType, index, array) => (
            <button
              key={optionType}
              onClick={() => handleOptionChange(optionType)}
              className={`flex-1 py-1 px-4 text-lg  font-medium transition-colors duration-300 ease-in-out ${
                isOptionSelected(optionType)
                  ? "bg-primary-500 text-white"
                  : "text-black hover:text-primary-500 hover:border-primary-500"
              } ${index === 0 ? "rounded-l-md" : ""} ${
                index === array.length - 1 ? "rounded-r-md" : ""
              }`}
            >
              {t(`pos.subscription.buttons.${optionType}`)}
            </button>
          ))}
        </div>
        {isOptionSelected("buy") && (
          <Buy
            selectedBuyOption={selectedSubscription}
            onSelectBuyOption={setSelectedSubscription}
          />
        )}
        {isOptionSelected("lease") && (
          <Lease
            selectedLeaseOption={selectedSubscription}
            onSelectLeaseOption={setSelectedSubscription}
          />
        )}
      </div>
      <div className="flex items-center justify-left md:mt-3 md:ml-2">
        <div className="flex space-x-2 hover:underline hover:cursor-pointer hover:text-primary-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
            />
          </svg>

          <span onClick={handleOpenModal}>
            {t("pos.subscription.info.title")}
          </span>
        </div>
      </div>
      {isModalOpen && (
        <Modal onClose={handleCloseModal} className="w-full max-w-3xl">
          <div className="w-full max-w-2xl mx-auto">
            <table className="min-w-full">
              <thead className="text-black">
                <tr>
                  <th
                    scope="col"
                    className="px-5 py-3 border-b border-gray-400 text-center text-xl font-semibold w-full"
                    colSpan={2}
                  >
                    {t("pos.subscription.info.p1")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-5 py-3 border-b border-gray-400 bg-primary text-black">
                    <p className="whitespace-no-wrap">
                      {t("pos.subscription.info.debit")}
                    </p>
                  </td>
                  <td className="px-5 py-3 border-b border-gray-400 bg-white">
                    <div className="text-black flex justify-between border-b border-gray-300 pt-0 pb-3">
                      <p className="whitespace-no-wrap">
                        {t("pos.subscription.info.domesticConsumer")}
                      </p>
                      <p className="text-right">
                        {isPartnerYellow ? "€0,065" : "€0,08"}
                      </p>
                    </div>
                    <div className="text-black flex justify-between border-b border-gray-300 py-3">
                      <p className="whitespace-no-wrap">
                        {t("pos.subscription.info.nonDomesticConsumer")}
                      </p>
                      <p className="text-right">€0,02 + 1,15%</p>
                    </div>
                    <div className="text-black flex justify-between pt-3 pb-0">
                      <p className="whitespace-no-wrap">
                        {t("pos.subscription.info.commercial")}
                      </p>
                      <p className="text-right">€0,02 + 1,15%</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    className="px-5 py-3 border-b border-gray-400 bg-primary text-black"
                    rowSpan={3}
                  >
                    <p className="whitespace-no-wrap">
                      {t("pos.subscription.info.credit")}
                    </p>
                  </td>
                  <td className="px-5 py-3 border-b border-gray-300 bg-white">
                    <div className="text-black flex justify-between">
                      <p className="whitespace-no-wrap">
                        {t("pos.subscription.info.eu")}
                      </p>
                      <p className="text-right">1,35%</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="px-5 py-3 border-b border-gray-300 bg-white">
                    <div className="text-black flex justify-between">
                      <p className="whitespace-no-wrap">
                        {t("pos.subscription.info.nonEu")}
                      </p>
                      <p className="text-right">2,30%</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="px-5 py-3 border-b border-gray-400 bg-white">
                    <div className="text-black flex justify-between">
                      <p className="whitespace-no-wrap">
                        {t("pos.subscription.info.commercial")}
                      </p>
                      <p className="text-right">2,30%</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="px-5 py-3 border-b border-gray-400 bg-primary text-black">
                    <p className="whitespace-no-wrap">
                      {t("pos.subscription.info.refund")}
                    </p>
                  </td>
                  <td className="px-5 py-3 border-b border-gray-400 bg-white">
                    <div className="text-black flex justify-between">
                      <p className="whitespace-no-wrap"></p>
                      <p className="text-right">€0,12</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal>
      )}
      <div className="flex justify-end">
        <button
          onClick={handleContinue}
          className="mt-4 bg-primary-500 text-black font-bold py-2 px-4 rounded hover:bg-primary-600 transition duration-300"
        >
          {t("pos.buttons.continue.name")}
        </button>
      </div>
    </div>
  );
};

export default Subscription;
