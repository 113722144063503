import React from "react";
import { Routes, Route } from "react-router-dom";
import { Terminal, Subscription, Accessories, OrderReview } from "../pages/pos";
import Location from "../pages/pos/Location";
import MigrateTerminal from "../pages/pos/MigrateTerminal";
import MigrateReview from "../pages/pos/MigrateReview";
import MigrateLocation from "../pages/pos/MigrateLocation";
import TapToPayFlow from "../pages/pos/softPos/TapToPayFlow";
import SoftPosLocation from "../pages/pos/softPos/SoftPosLocation";
import SoftPosConfiguration from "../pages/pos/softPos/SoftPosConfiguration";
import SoftPosConfirmation from "../pages/pos/softPos/SoftPosConfirmation";
import MigrationFlow from "../pages/pos/MigrationFlow";
import NewTerminalFlow from "../pages/pos/NewTerminalFlow";

const PosRoutes: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/*" element={<NewTerminalFlow />}>
          <Route path="terminal" element={<Terminal />} />
          <Route path="subscription" element={<Subscription />} />
          <Route path="accessories" element={<Accessories />} />
          <Route path="location" element={<Location />} />
          <Route path="review" element={<OrderReview />} />
        </Route>

        <Route path="migrate/*" element={<MigrationFlow />}>
          <Route path="location" element={<MigrateLocation />} />
          <Route path="terminal" element={<MigrateTerminal />} />
          <Route path="review" element={<MigrateReview />} />
        </Route>

        <Route path="taptopay/*" element={<TapToPayFlow />}>
          <Route path="location" element={<SoftPosLocation />} />
          <Route path="configuration" element={<SoftPosConfiguration />} />
          <Route path="confirmation" element={<SoftPosConfirmation />} />
        </Route>
      </Routes>
    </>
  );
};

export default PosRoutes;
