import { MigrateType } from "../interfaces/IPos";

const CountryOptions = [
  { value: "Netherlands", code: "NL" },
  { value: "Germany", code: "DE" },
  { value: "Belgium", code: "BE" },
];

export const transformMigrateToPayload = (migrate: MigrateType) => {
  if (!migrate.location) {
    return {
      orderLines: [],
      locations: [],
      deliveryAddress: {},
    };
  }

  const getCountryCodeOrValue = (countryName: string) => {
    const countryOption = CountryOptions.find(
      (option) => option.value === countryName
    );
    return countryOption ? countryOption.code : countryName;
  };

  const locationId = migrate.location.id.toString();

  const orderLines = migrate.TIDs.map((tid) => ({
    locationId: locationId,
    transactionService: {
      terminal: {
        tid: tid,
      },
      recurringFee: 3.35,
      contractFee: 58.0,
    },
  }));

  const locationAddress = {
    street: migrate.location.street,
    houseNumber: migrate.location.houseNumber,
    houseNumberExtension: migrate.location.addition || "",
    additionalAddressLine: migrate.location.addition || "",
    postalCode: migrate.location.postalCode,
    city: migrate.location.city,
    countryCode: getCountryCodeOrValue(migrate.location.country || ""),
  };

  const locations = [
    {
      locationId,
      locationName: migrate.location.locationName || "",
      locationAddress: locationAddress,
    },
  ];

  const deliveryAddressMigrate = { ...locationAddress };

  return {
    orderLines,
    locations,
    deliveryAddressMigrate,
  };
};
