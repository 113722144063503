import React, { useEffect, useState, useRef } from "react";

interface ModalProps {
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  className?: string;
  showCloseIcon?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  onClose,
  title,
  children,
  className = "",
  showCloseIcon = true,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") onClose();
    };

    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [onClose]);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div
      className={`fixed inset-0 z-50 flex justify-center items-center p-4 ease-linear  ${
        isVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      } bg-gray-800 bg-opacity-30`}
    >
      <div
        ref={modalRef}
        className={`bg-white p-2 md:p-6 rounded-md shadow-lg relative max-w-lg w-full ${className} transition transform ease-out duration-300 scale-95 hover:scale-100`}
        role="dialog"
        aria-modal="true"
        aria-labelledby={title ? "modalTitle" : undefined}
      >
        {showCloseIcon && (
          <button
            onClick={onClose}
            className="absolute top-1 right-2 text-lg text-gray-600 hover:text-gray-800"
            aria-label="Close"
          >
            X
          </button>
        )}
        {title && (
          <h3 id="modalTitle" className="ml-1 text-md sm:text-lg font-semibold mb-4">
            {title}
          </h3>
        )}
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
