import toast from "react-hot-toast";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { PosContext } from "../../../context/PosContext";
import useCustomNavigate from "../../../components/pos/hooks/useCustomNavigate";
import YazaraPhone from "../../../components/pos/ui/YazaraPhone";
import Input from "../../../components/pos/ui/TaptopayInput";

const SoftPosConfiguration = () => {
  const { updateSteps, softPosCreationData, updateSoftPosCreationData } =
    useContext(PosContext);

  const [terminalName, setTerminalName] = useState<string>(
    softPosCreationData.terminalName || ""
  );
  const [emailAddress, setEmailAddress] = useState<string>(
    softPosCreationData.emailAddress || ""
  );

  const navigate = useCustomNavigate();
  const { t } = useTranslation();

  const handleContinue = (e: any) => {
    e.preventDefault();

    if (
      !terminalName ||
      terminalName === "undefined" ||
      !emailAddress ||
      emailAddress === "undefined"
    ) {
      toast.error(`${t("terminals.softpos.toast.noCompleteConfigs")}`);
      return;
    }

    if (terminalName.length > 30) {
      toast.error(`${t("terminals.softpos.toast.invalidTerminalName")}`);
      return;
    }

    var emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!emailRegex.test(emailAddress)) {
      toast.error(`${t("terminals.softpos.toast.invalidEmail")}`);
      return;
    }

    updateSoftPosCreationData({
      terminalName: terminalName,
      emailAddress: emailAddress,
    });

    updateSteps({ softposconfiguration: true });
    navigate(`/pos/taptopay/confirmation`);
  };

  const handleTerminalNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTerminalName(event.target.value);
  };

  const handleEmailAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmailAddress(event.target.value);
  };

  return (
    <div className="container mx-auto px-4 py-8 pt-4 w-full md:max-w-3xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl">
      <div
        className="flex items-center text-primary-500 cursor-pointer mb-6"
        onClick={() => navigate("/pos/taptopay/location")}
      >
        <svg
          className="w-6 h-6 mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 19l-7-7 7-7"
          ></path>
        </svg>
        {t("pos.buttons.thirdParty.hint")}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2">
        <div className="flex flex-col">
          <h1 className="text-2xl font-bold mb-2 text-left">
            {t("terminals.softpos.configuration.title")}
          </h1>
          <p className=" text-primary-600 text-lg font-semibold mb-6">
            {t("terminals.softpos.configuration.p1")}
          </p>
          <div className="sm:w-1/2 w-full h-full space-y-6">
            <Input
              label={t(
                "terminals.softpos.configuration.placeholder.terminalName"
              )}
              name="terminalName"
              id="terminalName"
              type="text"
              value={terminalName}
              onChange={handleTerminalNameChange}
              className="w-72"
            />
            <Input
              label={t("terminals.softpos.configuration.placeholder.email")}
              name="emailAddress"
              id="emailAddress"
              type="email"
              value={emailAddress}
              onChange={handleEmailAddressChange}
              className="w-72"
            />
          </div>
          <div className="flex justify-end mt-10">
            <button
              onClick={handleContinue}
              type="submit"
              className="mt-20 bg-primary-500 text-black font-bold py-2 px-4 rounded hover:bg-primary-600 transition duration-300"
            >
              {t("pos.buttons.continue.name")}
            </button>
          </div>
        </div>

        <div className="w-full h-full flex justify-center my-6">
          <YazaraPhone />
        </div>
      </div>
    </div>
  );
};

export default SoftPosConfiguration;
