import React, { useContext, useState } from "react";
import { ISelectedAccessory } from "../../../interfaces/IPos";
import Modal from "../ui/Modal";
import { PosContext } from "../../../context/PosContext";
import { t } from "i18next";
import { formatPrice } from "../utilities/formatPrice";
import { getLocalizedDescription } from "../utilities/getLocalizedDescription";
import { useTranslation } from "react-i18next";

interface OrderReviewAccessoryProps {
  accessory: ISelectedAccessory;
}

const OrderReviewAccessory: React.FC<OrderReviewAccessoryProps> = ({
  accessory,
}) => {
  const { removeAccessory, handleAccessoryQuantityChange } =
    useContext(PosContext);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [accessoryToDelete, setAccessoryToDelete] =
    useState<ISelectedAccessory | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleQuantityChange = (
    accessory: ISelectedAccessory,
    quantityChange: number
  ) => {
    const newQuantity = accessory.quantity + quantityChange;

    if (newQuantity <= 0) {
      setAccessoryToDelete(accessory);
      setIsModalOpen(true);
    } else {
      handleAccessoryQuantityChange(
        accessory.accessory.InvoiceCode,
        quantityChange
      );
    }
  };

  const handleRemoveClick = () => {
    setAccessoryToDelete(accessory);
    setIsModalOpen(true);
  };

  const handleConfirmRemove = () => {
    if (accessoryToDelete) {
      removeAccessory(accessoryToDelete.accessory.InvoiceCode);
      setAccessoryToDelete(null);
      setIsModalOpen(false);
    }
  };

  const { accessory: accessoryDetail, quantity } = accessory;
  const { ProductDescriptions, InvoiceCode, Fees } = accessoryDetail;

  const price = Fees[0]?.Price || 0;
  const totalPrice = price * quantity;

  return (
    <div className="grid grid-cols-1 md:grid-cols-12 p-4">
      {isModalOpen && (
        <Modal
          onClose={() => setIsModalOpen(false)}
          title={`${t("pos.accessories.delete.remove")}`}>
          <p className="text-lg text-gray-800 mb-4"></p>

          <div className="flex mr-2 mb-2 justify-end space-x-3 sm:space-x-5">
            <button
              onClick={() => setIsModalOpen(false)}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-900 font-semibold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-gray-300">
              {t("pos.accessories.delete.cancelBtn")}
            </button>
            <button
              onClick={handleConfirmRemove}
              className="bg-red-200 hover:bg-red-300 text-red-800 hover:text-red-900 font-semibold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-red-300">
              {t("pos.accessories.delete.removeBtn")}
            </button>
          </div>
        </Modal>
      )}
      <div className="md:col-span-3 flex justify-start items-center">
        <h2 className="text-lg font-semibold text-gray-800">
          {getLocalizedDescription(ProductDescriptions, currentLanguage)}
        </h2>
      </div>

      <div className="md:col-span-2  flex justify-start items-center">
        €{formatPrice(price)}
      </div>

      <div className="md:col-span-5 flex items-center justify-end sm:justify-start">
        <button
          onClick={() => handleQuantityChange(accessory, -1)}
          className={`text-gray-600 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 p-2 rounded-full transition duration-300 ease-in-out`}>
          −
        </button>
        <span className="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-100 rounded-lg mx-2">
          {quantity}
        </span>
        <button
          onClick={() => handleQuantityChange(accessory, 1)}
          className="text-gray-600 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 p-2 rounded-full transition duration-300 ease-in-out">
          +
        </button>
      </div>

      <div className="md:col-span-1 text-lg font-semibold text-gray-800 text-right flex justify-start self-center">
        €{formatPrice(totalPrice)}
      </div>

      <div className="md:col-span-1 flex justify-end items-center">
        <button
          onClick={handleRemoveClick}
          className="col-span-1 hover:text-red-500 active:text-red-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default OrderReviewAccessory;
