import React, { useContext } from "react";
import { PosContext } from "../../context/PosContext";
import useCustomNavigate from "../../components/pos/hooks/useCustomNavigate";
import { t } from "i18next";
import MapView from "../../components/pos/ui/MapView";
import { formatPrice } from "../../components/pos/utilities/formatPrice";
import { useDetermineOrderType } from "../../components/pos/hooks/useDetermineOrderType";
import toast from "react-hot-toast";

const MigrateReview: React.FC = () => {
  const navigate = useCustomNavigate();
  const { migrate, terminals, updateOrderType } = useContext(PosContext);
  const orderType = useDetermineOrderType();

  const transactiedienstTerminal = terminals.find((terminal) =>
    terminal.ProductName.includes("SEPAY Transactiedienst")
  );

  const handleFinish = () => {
    if (migrate.location && migrate.TIDs?.length > 0) {
      updateOrderType(orderType);
      navigate("/onboarding");
    } else {
      toast.error(
        t(
          `${t("errors.toastError.migrate.title")} : ${
            !migrate.location ? t("errors.toastError.migrate.location") : ""
          } ${!migrate.TIDs?.length ? t("errors.toastError.migrate.tid") : ""}`
        )
      );
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 pt-4 bg-white w-full md:max-w-3xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl">
      <div
        className="flex items-center text-primary-500 cursor-pointer mb-6"
        onClick={() => navigate("/pos/migrate/terminal")}>
        <svg
          className="w-6 h-6 mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 19l-7-7 7-7"></path>
        </svg>
        {t("pos.buttons.thirdParty.hint")}
      </div>
      <h1 className="text-3xl font-bold mb-2 text-left">
        {t("pos.buttons.review.title")}
      </h1>
      <p className="text-lg text-primary-600 mb-6 font-semibold">
        {t("pos.buttons.review.activate")}
      </p>
      <h2 className="text-lg font-semibold text-gray-800">TID(s) :</h2>
      <ul className="flex flex-col items-start">
        {migrate.TIDs.map((tid, index) => (
          <li
            key={index}
            className="flex space-x-10 px-3 py-1 ml-5 transition-colors font-normal">
            <span></span>
            {tid}
            <span>
              €
              {formatPrice(
                transactiedienstTerminal?.Subscriptions[0].Fees.find(
                  (fee) => fee.Type === "RecurringFee"
                )?.Price ?? 0
              )}{" "}
              / {t("pos.purchaseTotal.month")}
            </span>
            <span>
              €
              {formatPrice(
                transactiedienstTerminal?.Subscriptions[0].Fees.find(
                  (fee) => fee.Type === "OneTimeFee"
                )?.Price ?? 0
              )}{" "}
              {t("pos.buttons.review.fee")}
            </span>
          </li>
        ))}
      </ul>
      <div className="text-lg font-semibold text-gray-800 mt-4">
        {t("pos.review.location")}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {migrate.location && (
          <div
            className={`flex flex-col justify-between p-2 mt-2 border ml-12 sm:ml-0 border-gray-200 rounded-xl shadow transition duration-300 transform hover:shadow-lg w-72`}>
            <MapView
              street={migrate.location.street}
              houseNumber={migrate.location.houseNumber}
              addition={migrate.location.addition}
              postalCode={migrate.location.postalCode}
              city={migrate.location.city}
              country={migrate.location.country}
              className="mb-4 border flex justify-center"
              imgClassName="rounded-lg"
              zoom={17}
            />
            <div className="space-y-1 mb-2 px-3">
              <h3 className="text-xl font-semibold text-gray-800">
                {migrate.location.locationName}
              </h3>
              <p className="text-sm text-gray-600">
                {migrate.location.street}, {migrate.location.houseNumber}{" "}
                {migrate.location.addition}
              </p>
              <p className="text-sm text-gray-600">
                {migrate.location.postalCode}, {migrate.location.city},{" "}
                {migrate.location.country}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-end">
        <button
          onClick={() => handleFinish()}
          className="mt-4 bg-primary-500 text-black font-bold py-2 px-4 rounded hover:bg-primary-600 transition duration-300">
          {t("pos.buttons.migrate")}
        </button>
      </div>
    </div>
  );
};

export default MigrateReview;
