import { IOrder, ISelectedAccessory, LocationType } from "../interfaces/IPos";

const CountryOptions = [
  { value: "Netherlands", code: "NL" },
  { value: "Germany", code: "DE" },
  { value: "Belgium", code: "BE" },
];

export const transformOrdersToRequestPayload = (
  orders: IOrder[],
  deliveryAddress: LocationType,
  aggregatedAccessories: ISelectedAccessory[]
) => {
  const orderLines = orders.flatMap((order) => {
    if (!order.selectedTerminal || !order.location) return [];

    const buyFee =
      order.selectedSubscription?.Fees.find((fee) => fee.Type === "BuyFee")
        ?.Price || 0;
    const recurringFee =
      order.selectedSubscription?.Fees.find(
        (fee) => fee.Type === "RecurringFee"
      )?.Price || 0;
    const contractFee =
      order.selectedSubscription?.Fees.find((fee) => fee.Type === "OneTimeFee")
        ?.Price || 0;

    const terminalProductLine = {
      locationId: order.location.id.toString(),
      terminalProduct: {
        productId: order?.selectedTerminal?.InvoiceCode,
        quantity: order.selectedTerminal.quantity || 1,
        buyFee: buyFee,
        terminalSubscription: {
          subscriptionId:
            order.selectedSubscription?.SubscriptionInvoiceCode || "",
          recurringFee: recurringFee,
          contractFee: contractFee,
        },
      },
    };

    const accessoryProductLines = aggregatedAccessories.map((acc) => {
      const buyFee =
        acc.accessory.Fees.find((fee) => fee.Type === "BuyFee")?.Price || 0;

      return {
        accessoryProduct: {
          productId: acc.accessory.InvoiceCode,
          quantity: acc.quantity,
          buyFee: buyFee,
        },
      };
    });

    return [terminalProductLine, ...accessoryProductLines];
  });

  const uniqueAccessoryProductLines = Array.from(
    new Set(orderLines.map((line) => JSON.stringify(line)))
  ).map((str) => JSON.parse(str));

  const getCountryCodeOrValue = (countryName: string) => {
    const countryOption = CountryOptions.find(
      (option) => option.value === countryName
    );
    return countryOption ? countryOption.code : countryName;
  };

  const locations = Array.from(
    new Map(
      orders
        .map<[string, any] | undefined>((order) => {
          if (!order.location) return undefined;
          const locationId = order.location.id.toString();
          return [
            locationId,
            {
              locationId: locationId,
              locationName: order.location.locationName,
              locationAddress: {
                street: order.location.street,
                houseNumber: order.location.houseNumber,
                houseNumberExtension: order.location.addition || "",
                additionalAddressLine: order.location.addition || "",
                postalCode: order.location.postalCode,
                city: order.location.city,
                countryCode: getCountryCodeOrValue(
                  order.location.country || ""
                ),
              },
            },
          ];
        })
        .filter((entry): entry is [string, any] => entry !== undefined)
    ).values()
  );

  const deliveryAddressFormatted = {
    street: deliveryAddress.street,
    houseNumber: deliveryAddress.houseNumber,
    houseNumberExtension: deliveryAddress.addition || "",
    additionalAddressLine: deliveryAddress.addition || "",
    postalCode: deliveryAddress.postalCode,
    city: deliveryAddress.city,
    countryCode: getCountryCodeOrValue(deliveryAddress.country || ""),
  };

  return {
    deliveryAddress: deliveryAddressFormatted,
    orderLines: uniqueAccessoryProductLines,
    locations,
  };
};
