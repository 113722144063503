import React, { useContext } from "react";
import { PosContext } from "../../context/PosContext";
import { IAccessory } from "../../interfaces/IAccessory";
import AccessoryCard from "../../components/pos/cards/AccessoryCard";
import useCustomNavigate from "../../components/pos/hooks/useCustomNavigate";
import { t } from "i18next";

const Accessories: React.FC = () => {
  const {
    updateOrder,
    accessories,
    updateSteps,
    orders,
    activeOrderId,
    updateAggregatedAccessories,
  } = useContext(PosContext);

  const navigate = useCustomNavigate();
  const activeOrder = orders.find((order) => order.orderId === activeOrderId);

  const handleSelectAccessory = (
    selectedAccessory: IAccessory,
    quantity: number
  ) => {
    const currentSelectedAccessories = activeOrder?.selectedAccessories || [];

    const existingAccessoryIndex = currentSelectedAccessories.findIndex(
      (item) => item.accessory.InvoiceCode === selectedAccessory.InvoiceCode
    );

    if (existingAccessoryIndex !== -1) {
      currentSelectedAccessories[existingAccessoryIndex].quantity = quantity;
    } else {
      currentSelectedAccessories.push({
        accessory: selectedAccessory,
        quantity,
      });
    }

    updateOrder({ selectedAccessories: currentSelectedAccessories });
    updateAggregatedAccessories(currentSelectedAccessories);
  };

  const handleContinue = () => {
    updateSteps({ accessories: true });
    navigate("/pos/location");
  };

  const handleBack = () => {
    navigate("/pos/subscription");
  };

  const filteredAccessories = accessories.filter((accessory) =>
    accessory.Compatibilities.includes(
      activeOrder?.selectedTerminal?.ProductName ?? ""
    )
  );

  const emptyMessage = (
    <div className="text-center py-10">
      <div className="flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-10 h-10 text-primary-500"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636"
          />
        </svg>
        <p className="text-primary-700 ml-5">
          {t("pos.accessories.noAccessories")}
        </p>
      </div>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8 pt-4 bg-white w-full md:max-w-3xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl">
      <div
        className="flex items-center text-primary-500 cursor-pointer mb-6"
        onClick={handleBack}
      >
        <svg
          className="w-6 h-6 mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 19l-7-7 7-7"
          ></path>
        </svg>
        {t("pos.buttons.back.name")}
      </div>
      <h1 className="text-3xl font-bold mb-8 text-left">
        {t("pos.accessories.title")}
      </h1>
      <div className="flex flex-wrap justify-center md:justify-start gap-x-4 gap-y-8 xl:gap-5">
        {filteredAccessories.length > 0
          ? filteredAccessories.map((accessory: IAccessory) => (
              <AccessoryCard
                key={accessory.InvoiceCode}
                accessory={accessory}
                onSelect={handleSelectAccessory}
              />
            ))
          : emptyMessage}
      </div>
      <div className="flex justify-end mt-4">
        <button
          onClick={handleContinue}
          className="mt-4 bg-primary-500 text-black font-bold py-2 px-4 rounded hover:bg-primary-600 transition duration-300"
        >
          {t("pos.buttons.continue.name")}
        </button>
      </div>
    </div>
  );
};

export default Accessories;
