import { useState } from "react";
import OnboardingForm from "../../components/forms/OnboardingForm";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Success from "../success";
import { OverlayStatus } from "../../enums/OverlayStaus";
import { useTranslation } from "react-i18next";

const Onboarding = () => {
  const [isNetherlands, setIsNetherlands] = useState<boolean>(true);
  const [overlayStatus, setOverlayStatus] = useState<OverlayStatus>(
    OverlayStatus.None
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");

  const { t } = useTranslation();

  const handleCountryChange = () => {
    setIsNetherlands(!isNetherlands);
  };

  const svgBackground = encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 846 868" fill="none">
          <path
            d="M561.862 365.638H293.028l139.479 312.545 129.355-312.545zM0 0h126.842l115.364 254.285h367.888L719.158 0H846L474.402 868h-87.576L0 0z"
            fill="#F2F6D7"
          />
        </svg>
        `);

  if (overlayStatus === OverlayStatus.None && isFormSubmitted) {
    return <Success firstName={firstName} companyName={companyName} />;
  }
  return (
    <>
      <style>
        {`
          .btn {
            position: relative;
            overflow: hidden;
            transition: color 0.3s;
          }

          .btn::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: black;
            top: 0;
            left: -100%;
            transition: transform 0.3s ease-in;
            z-index: 0;
          }

          .btn:hover::before {
            transform: translateX(100%);
          }

          .btn span {
            position: relative;
            z-index: 1;
          }

          .btn:hover span {
            color: white;
          }
          
          // .grecaptcha-badge { visibility: hidden; }
        `}
      </style>

      <div
        className="flex flex-col md:flex-row min-h-screen w-full bg-white"
        style={{ minHeight: `calc(100vh - 79px)` }}
      >
        <div className="w-full md:w-1/2 flex items-start justify-center my-10 md:my-0 px-4 md:px-10 lg:px-20">
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
            language="en"
          >
            <OnboardingForm
              isNetherlands={isNetherlands}
              setOverlayStatus={setOverlayStatus}
              overlayStatus={overlayStatus}
              setIsFormSubmitted={setIsFormSubmitted}
              setFirstName={setFirstName}
              setCompanyName={setCompanyName}
            />
          </GoogleReCaptchaProvider>
        </div>
        <div
          className="w-full md:w-1/2 flex flex-col items-center justify-center bg-no-repeat bg-contain bg-top px-4 md:px-10 lg:px-20"
          style={{
            backgroundImage: `url("data:image/svg+xml,${svgBackground}")`,
          }}
        >
          <div className="flex flex-col items-center justify-start pt-10 md:pt-20 h-full md:mt-48">
            <h1 className="text-3xl lg:text-4xl font-bold text-center text-black mb-10">
              {isNetherlands
                ? t("headers.infoFormOut")
                : t("headers.infoFormIn")}
            </h1>
            <button
              data-testid="countryChange"
              onClick={handleCountryChange}
              className="btn px-7 py-3 border border-black rounded-lg text-black uppercase 
              font-semibold tracking-wider leading-none overflow-hidden transition-colors duration-300"
              type="button"
            >
              <span>
                {isNetherlands ? t("buttons.buttonOut") : t("buttons.buttonIn")}
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Onboarding;
