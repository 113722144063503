import React, { useEffect } from "react";
import confetti from "canvas-confetti";
import { OverlayStatus } from "../../enums/OverlayStaus";

const LoadingOverlay: React.FC<{
  status: OverlayStatus;
  onExit: () => void;
}> = ({ status, onExit }) => {
  useEffect(() => {
    if (status === OverlayStatus.Success) {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
        colors: [
          "#c1d83a",
          "#f3f7d8",
          "#212121",
          "#9aad2e",
          "#748223",
          "#f2f6d7",
        ],
      });
      const timeoutId = window.setTimeout(() => {
        onExit();
      }, 1500);
      return () => window.clearTimeout(timeoutId);
    }
    if (status === OverlayStatus.Error) {
      onExit();
    }
  }, [status, onExit]);

  // const iconBaseStyle =
  //   "w-16 h-16 transition-all duration-500 ease-out transform";
  // const iconVisibleStyle = "scale-100 opacity-100";
  // const iconHiddenStyle = "scale-0 opacity-0";

  const iconBaseStyle =
    "w-16 h-16 transition-all duration-500 ease-out transform";
  const circleStyle =
    "flex items-center justify-center rounded-full bg-primary-500 p-4";

  if (status === OverlayStatus.None) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50 transition-opacity">
      {status === OverlayStatus.Loading && (
        <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-r-4 border-primary-500" />
      )}
      {status === OverlayStatus.Success && (
        <div className="flex flex-col items-center">
          <div className={circleStyle}>
            <svg
              className={`${iconBaseStyle} text-black`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoadingOverlay;
